module.exports = [{
      plugin: require('/Users/jordanholland/Documents/gatsby/kintimer/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-75096825-12"},
    },{
      plugin: require('/Users/jordanholland/Documents/gatsby/kintimer/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jordanholland/Documents/gatsby/kintimer/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
